<template>
  <div>
    <div class="shadow-md relative rounded-2xl bg-white flex gap-6 flex-col">
      <div
        class="p-3 panel-head bg-primary-300 font-bold text-base-content flex gap-3 justify-between items-center rounded-t-2xl"
      >
        Assigned Tenants
      </div>
      <div class="bg-white p-6 rounded-lg py-24 flex items-center justify-center" v-if="loadingTenants">
            <Loader />
        </div>
      <div class="py-3 px-6" v-else>
        <custom-table :columns="tableHeaders" :rows="rowsData" selectAll searchBar :handleSelectAll="handleSelect" @searchedValue = "getSearchedValue"
e          ><template v-slot:title="props">
            <div class="flex gap-1 ml-2">
              <input
                :key="props.item.id"
                type="checkbox"
                :checked="props.item.selected"
                v-model="props.item.selected"
                class="checkbox checkbox-xs mt-1 ml-1"
                @change="reArrange"
              />
              <span>{{ props.item.title }}</span>
            </div>
          </template>
          <template v-slot:list_price="props">
            <label class="input-group input-group-xs justify-center">
              <span>{{ props.item.currency_symbol || '$' }}</span>
              <input
                :disabled="disableCheckDefaultprice(props.item)"
                type="text"
                class="input input-bordered input-xs w-2/4"
                v-model="props.item.list_price"
                @input="handleDiscountedPrice(props.item)"
              />
            </label>
          </template>
          <template v-slot:default_price="props">
            <div class="justify-center">
            <input
              type="checkbox"
              :checked="props.item.default_price"
              v-model="props.item.default_price"
              class="checkbox checkbox-xs mt-1 ml-3"
              @change="defaultPrice(props.item)"
            /></div>
          </template>
          <template v-slot:discount="props">
            <label class="input-group input-group-xs justify-center">
              <span>%</span>
              <input
                type="text"
                class="input input-bordered input-xs w-2/4"
                v-model="props.item.discount"
                @input="handleDiscountedPrice(props.item)"
                @keypress="isNumber($event)"
              />
            </label>
            <span v-if="props.item.invalidDiscount" class="text-red-700 text-sm">{{ props.item.errorMsg }}</span>
          </template>
           <template v-slot:discounted_price="props">
            <label class="input-group input-group-xs justify-center">
              <span>{{ props.item.currency_symbol || '$' }}</span>
              <input
                type="text"
                class="input input-bordered input-xs w-2/4"
                v-model="props.item.discounted_price"
                disabled
              />
            </label>
          </template>
        </custom-table>
         <div class="flex justify-end max-w-full">
              <Button
                :disabled="!changeMade"
                @click="assignPacks"
                class="btn-primary float-right mt-4"
                text="Save"
                :loader="savingAssignPacks"
              />
            </div>
      </div>
    </div>
  </div>
</template>
<script>
import customTable from "@shared/components/custom-table";
import Loader from "@shared/loader";
import Button from "@/components/button";
import {getTenants, getCurrencies, getAssignedTenants, assignTenantsPacks, getPackDetails} from "../services";
import { checkDiscount, isNumber } from "@/plugins/functions"
export default {
  name: "assignCreditTenant",
  data: () => {
    return {
      changeMade: false,
      tableHeaders: [
        { name: "Name", type: "custom", label: "title" },
        { name: "Currency", type: "text", label: "currency_code" },
        { name: "List Price", type: "custom", label: "list_price" },
        { name: "Default List Price", type: "custom", label: "default_price" },
        { name: "Discount", type: "custom", label: "discount" },
        { name: "Discounted Price", type: "custom", label: "discounted_price" },
        { name: "selected", type: "extra", label: "selected" },
      ],
      rowsData: [
      ],
      savedPacks:[],
      savingAssignPacks: false,
      loadingTenants: false,
      hasDiscountError: false,
      searchedValue:""
    };
  },
  props: {
    packDetailsUpdate: {
      type: Number
    }
  },
  computed: {
     editMode() {
      return this.$route.params.id ? true : false
    }
  },
  components: {
    customTable,
    Button,
    Loader
  },
  watch:{
    rowsData: {
      deep: true,
      handler(){
        if (!this.loadingTenants) {
         this.changeMade = true
        }
      }
    },
    packDetailsUpdate: {
      deep: true,
      async handler(){
        // this.loadingTenants = true
        await this.setTenantsData()
        this.savedPacks = JSON.parse(JSON.stringify(this.rowsData))
        this.loadingTenants = false
      }
    }
  },
  async mounted() {
    // this.loadingTenants = true
    await this.setTenantsData()
    this.savedPacks = JSON.parse(JSON.stringify(this.rowsData))
    this.loadingTenants = false
  },
  methods: {
    checkDiscount,
    isNumber,
    getSearchedValue(val){
      this.searchedValue = val;
      if(this.searchedValue==""){
        this.reArrange();
      }
    },
    reArrange() {
     if(this.searchedValue==""){
      let selectedArr = [];
      let unSelectedArr = [];
      this.rowsData.forEach((item)=> {
        if(item.selected) {
          selectedArr.push(item)
        }
        else {
          unSelectedArr.push(item)
        }
      })
      this.rowsData = [...selectedArr, ...unSelectedArr]
    }},
    disableCheckDefaultprice (value) {
      if(value.default_price) {
        const discount = (value.list_price * value.discount) / 100;
        value.discounted_price = (value.list_price - discount).toFixed(value.decimal_place);
        return true
      }
      return false
    },
    async setTenantsData() {
      try {
        this.loadingTenants = true
        const {data} = await getPackDetails(this.$route.params.id);
        const tenantsData = await getTenants()
        const currencyData = await getCurrencies()
        let assignedTenants = await getAssignedTenants(this.$route.params.id)
        const masterCurrencies = data
        const masterCurrencyData = currencyData.data.data.find(mstCurr => mstCurr.base_currency)
        assignedTenants = assignedTenants.data.data.tenants
        let mappedCurrencies  = tenantsData?.data.map(({title,id,currency_id})=>{
          const masterCurrencyPrice = masterCurrencies?.prices.find((res) => res?.currency_id == masterCurrencyData.id)
          const defaultPrice = masterCurrencies.prices.find((res)=> res.currency_id == currency_id);
          const currency  = currencyData.data.data.find(res=> res.id === currency_id)
          const tenantData = assignedTenants.find(res=> res.tenant_id == id)
        return {
          title,
          id,
          currency_code: currency?.currency_code ? currency.currency_code : masterCurrencyData?.currency_code,
          list_price: tenantData?.list_price ? tenantData.list_price.toFixed(currency.decimal_place ? currency.decimal_place : masterCurrencyData?.decimal_place): defaultPrice ? defaultPrice.value.toFixed(currency.decimal_place ? currency.decimal_place : masterCurrencyData?.decimal_place) : masterCurrencyPrice?.value.toFixed(masterCurrencyData.decimal_place) ,
          discount: tenantData?.discount ? tenantData.discount: 0,
          validDiscountValue: tenantData?.discount ? tenantData.discount: 0,
          discounted_price: tenantData?.discounted_price ? tenantData.discounted_price: '',
          selected: tenantData ? true : false,
          currency_id: currency?.id ? currency.id : masterCurrencyData.id,
          default_price: tenantData ? tenantData?.default_price : true,
          currency_symbol : currency? currency?.currency_symbol : masterCurrencyData?.currency_symbol,
          decimal_place : currency?.decimal_place ? currency?.decimal_place : masterCurrencyData?.decimal_place,
         }
      
      })
      mappedCurrencies.sort((a,b)=>{
        if (a.selected && !b.selected) {
          return -1
        }
        else if (!a.selected && b.selected) {
          return 1
        }
        else {
          return 0
        }
      })
      this.rowsData = mappedCurrencies
      this.loadingTenants = false
      } catch (error) {
        this.loadingTenants = false
        if (Array.isArray(error?.response?.data?.detail)) {
          this.$toast.error(error?.response?.data?.detail?.[0]?.msg);
        } else {
          this.$toast.error(error?.response?.data?.detail);
        }
      }
    },
    async assignPacks() {
      try {
        if (this.hasDiscountError) {
          this.$toast.error('Invalid discount value!');
          return;
        }
      this.changeMade = false;
      this.savingAssignPacks = true
      let selectedTenants = this.rowsData.filter(res=> res.selected == true)
      let payload = {
        global_credit_pack_id: this.$route.params.id,
        tenant_info: []
      }
      if (selectedTenants.length > 0) {
        const fieldsDone = selectedTenants.every((res)=>{
          return res.discount.toString().length && res.list_price.toString().length
        })
        if (!fieldsDone) {
          this.$toast.error("Please fill all details.")
          this.savingAssignPacks = false
          return
        }
      }
      selectedTenants.forEach((res)=>{
        payload.tenant_info.push({
          tenant_id: res.id,
          currency_id: res.currency_id,
          list_price: res.list_price.toString(),
          discount: res.discount.toString(),
          discounted_price: res.discounted_price.toString(),
          default_price: res.default_price
        })
      })
      const { data } = await assignTenantsPacks(payload);
      this.savingAssignPacks = false
      // this.rowsData.sort((a,b)=>{
      //   if (a.selected && !b.selected) {
      //     return -1
      //   }
      //   else if (!a.selected && b.selected) {
      //     return 1
      //   }
      //   else {
      //     return 0
      //   }
      // })
      this.$toast.success(data!== null && data!== undefined && "Credit Pack Successfully assigned to Tenant")
      }
      catch (err) {
        if (Array.isArray(err?.response?.data?.detail)) {
          this.$toast.error(err?.response?.data?.detail?.[0]?.msg);
        } else {
          this.$toast.error(err?.response?.data?.detail); 
        }
        this.savingAssignPacks = false
      }
    },
    handleDiscountedPrice(value) {
      if(value.discount > 100) {
        value.discount = value.validDiscountValue
      }
      if(value.discount <= 100) {
        value.validDiscountValue = value.discount
        this.rowsData?.forEach(rows => {
          if (value.discount && !checkDiscount(value.discount, value.decimal_place)) {
            if (rows.id == value.id) {
              rows.invalidDiscount = true;
              rows.errorMsg = `Only ${value.decimal_place} decimal are allowed.`
              this.hasDiscountError = true;
            }
          } else {
            rows.invalidDiscount = false;
            rows.errorMsg = '';
            this.hasDiscountError = false;
          }
        })
        const discount = (value.list_price * value.discount) / 100;
        value.discounted_price = (value.list_price - discount).toFixed(value.decimal_place);
      }
    },
    async defaultPrice(value) {
      if (value.default_price) {
        let packdetails = await getPackDetails(this.$route.params.id)
        packdetails = packdetails.data.prices
        const savedPrice = packdetails.find(res => res.currency_id == value.currency_id)
        this.rowsData.forEach((res)=> {
            if (res.id == value.id) {
                res.list_price = savedPrice.value.toFixed(value.decimal_place)
                const discount = (res.list_price * value.discount) / 100;
                res.discounted_price = (res.list_price - discount).toFixed(value.decimal_place);
            }
        })
        }
    },
     handleSelect(selectedAll) {
      if (selectedAll) {
        this.rowsData.forEach((res) => {
          res.selected = true;
        });
      } else {
        this.rowsData.forEach((res) => {
          res.selected = false;
        });
      }
      this.changeMade = true;
    },
  },
};
</script>
<style>
  .input-group span{
    justify-content: center;
  }
</style>